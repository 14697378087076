import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';

import { Binder, Folder, Team } from '@app/shared/models';
import { calculateEntityPath, EntityPathItem } from '@app/shared/documents/calculate-entity-path.util';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { FolderDownloadOptions } from './folder-download.component.types';
import template from './folder-download.component.html';
import styles from './folder-download.component.scss';

@Component({
    selector: 'folder-download',
    template,
    styles: [String(styles)]
})
export class FolderDownloadComponent implements OnInit {
    @Input() options: FolderDownloadOptions;
    @Input() parent: Binder | Folder;
    @Input() team: Team;
    @Input() newDocViewerEnabled = false;
    @Output() download = new EventEmitter<FolderDownloadOptions>();

    includeSignature = true;
    includeAllVersionsOfDocuments = false;
    includesMultipleFolders = false;

    downloadPreview: string;
    theRestOfDownloadPreview: string;
    theRestOfDownloadFolderNames: string[] = [];
    path: EntityPathItem[];

    constructor(
        private modalRef: BsModalRef
    ) { }

    ngOnInit(): void {
        this.includesMultipleFolders = this.options.folders.length > 1;
        const entityForPath = this.includesMultipleFolders
            ? this.parent
            : {
                ...this.options.folders[0],
                binderName: this.parent.type === 'binder' ? this.parent.name : this.parent.binderName
            };
        this.path = calculateEntityPath(entityForPath, this.team);

        const folderNames = this.options.folders.map((folder) => folder.name);
        this.downloadPreview = folderNames.slice(0, 3).join(', ');
        if (folderNames.length > 3) {
            this.theRestOfDownloadPreview = `and ${folderNames.length - 3} others...`;
            this.theRestOfDownloadFolderNames = folderNames.splice(3, folderNames.length - 1);
        }
    }

    onStartDownload(): void {
        this.download.emit({
            ...this.options,
            includeSignature: this.includeSignature,
            includeAllVersionsOfDocuments: this.includeAllVersionsOfDocuments
        });
        this.cancel();
    }

    cancel(): void {
        this.modalRef.hide();
    }
}
