import * as _ from 'lodash';
import { SignatureDisabledModalComponent } from '@app/components/documents/components/signature-disabled/signature-disabled.component';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import AbstractFormFieldController from '../abstract.form.field/abstract.form.field.controller';
import AnnotationType from '../../../constants/annotation.type';
import ToolMode from '../../../constants/tool.mode';
import Actions from '../../../constants/document.content.actions';

class SignatureFormFieldController extends AbstractFormFieldController {
    constructor($scope, DocumentFormField, DocumentViewerToolsOptions, modalHelper,
        CurrentSession, DefaultSigningReason, DocumentSignatureFormat, private ModalsService: ModalsService) {

        super($scope, DocumentFormField, DocumentViewerToolsOptions);
        this._modalHelper = modalHelper;
        this._CurrentSession = CurrentSession;
        this._DefaultSigningReason = DefaultSigningReason;
        this._DocumentSignatureFormat = DocumentSignatureFormat;
    }

    $onInit(): void {

        super.$onInit();
        const userId = this._CurrentSession.getCurrentUser().id;
        const reason = this._DefaultSigningReason.getDefaultSigningReasonFromPending({ doc: this.doc, userId });
        this.annotationDefaults = reason ? { reason } : {};
    }

    $onChanges(changes: ng.IOnChangesObject): void {
        if (changes.isSelected && changes.isSelected.currentValue) {
            this._createAnnotation();
        }
    }

    toggleSignature() {
        let properties = {};

        if (this.annotation.textValue) {
            properties.textValue = '';
        }
        else {
            properties = Object.assign(properties, this._DocumentSignatureFormat.getSignature());
        }
        this._$scope.$emit(Actions.ANNOTATION_UPDATE_PROPERTIES, { page: this.page, annotation: this.annotation, properties });
    }


    canEdit() {
        return _.get(this.doc, 'permissions.signDocument', false) && !this.requestIsPastDue;
    }

    onClick() {
        if (!this.canEdit()) {
            return;
        }
        this.initialValue = true;

        this.onSelect({
            $event: {
                annotation: this.annotation,
                page: this.page,
                formField: this.data
            }
        });
    }

    isInteractive() {

        return this.canEdit() && !this.isReadOnly;
    }

    _createAnnotation() {
        if (this.isReadOnly || this.annotation) {
            return;
        }
        if (this.teamSignatureRestricted) {
            this.ModalsService.show(SignatureDisabledModalComponent, {
                initialState: {},
                class: 'modal-md'
            });
            return;
        }

        this.annotation = {
            id: this.data.id,
            textValue: '',
            ...this._DocumentViewerToolsOptions.getOptionValuesFor(ToolMode.SIGNATURE, this.data),
            ...this.data.coordinates,
            resizable: true,
            type: AnnotationType.signature,
            page: this.page
        };

        this.empty = false;
        if (this.initialValue) {
            this.initialValue = undefined;
            this.annotation = Object.assign(this.annotation, this._DocumentSignatureFormat.getSignature());
        }

        if (this.annotation.fontSize === this._DocumentViewerToolsOptions._initialFontSizeOption.value.fontSize) {
            this.annotation.fontSize = this._DocumentFormField.calculateFontSizeForField(this.page, this.data);
            this._DocumentViewerToolsOptions.setOptionsFor(
                ToolMode.ADD_TEXT,
                this.data,
                { fontSize: this.annotation.fontSize }
            );
        }

        this._$scope.$emit(Actions.ANNOTATION_CREATE, {
            annotation: this.annotation,
            formField: this.data
        });
    }
}

SignatureFormFieldController.$inject = [
    ...AbstractFormFieldController.$inject,
    'modalHelper',
    'CurrentSession',
    'DefaultSigningReason',
    'DocumentSignatureFormat',
    'ModalsService'
];

export default SignatureFormFieldController;
