import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import ToolMode from '../constants/tool.mode';
import Events from '../constants/document.content.events';

class DocumentViewerController {
    constructor(
        $scope, $element, $timeout,
        CurrentSession, DocumentViewerPageEvent,
        DocumentAnnotation, DocumentFormField, DocumentPageDisplay,
        DefaultSigningReason, modalHelper, DocumentViewerRotation,
        DocumentViewerStore
    ) {
        this._$scope = $scope;
        this._$element = $element;
        this._$timeout = $timeout;
        this._modalHelper = modalHelper;
        this._CurrentSession = CurrentSession;
        this._DocumentViewerPageEvent = DocumentViewerPageEvent;
        this._DocumentAnnotation = DocumentAnnotation;
        this._DocumentFormField = DocumentFormField;
        this._DocumentPageDisplay = DocumentPageDisplay;
        this._DefaultSigningReason = DefaultSigningReason;
        this.ToolMode = ToolMode;
        this._DocumentViewerRotation = DocumentViewerRotation;
        this._DocumentViewerStore = DocumentViewerStore;

        this.destroy$ = new Subject();

        // Track touches to derive a mobile-based selection gesture
        this.activeTouch = null;

        this.pageActionsVisible = false;
        this.isLoading = true;
        this.annotationsDisabled = false;
        this.unloadablePages = {};
        this.updateScrollTimeout = null;
        this.zoomFactor = 1;
        this.rotationAngle = 0;
        this.currentPage = 1;
        this.selectedFormFields = {};

        this.imgLoadError = _.debounce(this._imgLoadError, 1000);
        this.handlePageMouseDown = this._handlePageMouseDown.bind(this);
        this.handlePageMouseUp = this._handlePageMouseUp.bind(this);
        this.handlePageMouseMove = this._handlePageMouseMove.bind(this);
        this.debouncedOnResize = _.debounce(this._debouncedOnResize, 250, { trailing: true });
    }

    $onInit(): void {
        this.annotations = this._DocumentAnnotation.getAnnotations();
        this.formFields = this._DocumentFormField.getFormFields();
        this.currentPageIndex = 1;
        this.interactive = _.isUndefined(this.interactive) ? false : this.interactive;
        this.pages = this._DocumentPageDisplay.getPages();

        this.viewport = this._getViewportElement();
        this._calculateViewportWidth();
        this.loadPages();
        this._setSigningReasonFromPending();
        this._addListeners();

        this._DocumentViewerStore.selectedTool$
            .pipe(takeUntil(this.destroy$))
            .subscribe((tool) => {
                this.selectedTool = tool;
            });

        this._DocumentViewerStore.selectedObject$
            .pipe(takeUntil(this.destroy$))
            .subscribe((obj) => {
                this.selectedObject = obj;
                if (obj?.formField) {
                    this.selectedFormFields[obj.formField.id] = true;
                }
            });

        if (this.pages.length) {
            this.pages[this.currentPageIndex - 1].visibleInViewPort = true;
        }
    }

    $onDestroy(): void {
        if (this.updateScrollTimeout) {
            this._$timeout.cancel(this.updateScrollTimeout);
        }
        this._DocumentViewerRotation.resetState();

        this.destroy$.next();
        this.destroy$.complete();
    }

    formFieldVisible(page, ff): boolean {
        const isSelectedObject = this.selectedFormFields[ff.id];
        if (ff.pageNumber !== page.pageNumber) {
            return false;
        }
        return page.visibleInViewPort || isSelectedObject;
    }

    _addListeners() {
        // Some tools deactivate mouse events on annotations while they are active to
        // prevent interference
        this._$scope.$on(Events.ANNOTATIONS_DISABLE, () => {
            this.annotationsDisabled = true;
        });
        this._$scope.$on(Events.ANNOTATIONS_ENABLE, () => {
            this.annotationsDisabled = false;
        });
        this._$scope.$on(Events.PAGE_NAVIGATE_TO, this._navigateToPage.bind(this));
        this._$scope.$on(Events.PAGE_SCROLLED_TO, (event, { page }) => {
            this.currentPage = page;
        });
    }

    _setSigningReasonFromPending() {

        const userId = this._CurrentSession.getCurrentUser().id;
        this.defaultSigningReason = this._DefaultSigningReason.getDefaultSigningReasonFromPending({
            doc: this.doc,
            userId
        });
    }

    _navigateToPage($event, { page: pageNumber }) {
        const pageIndex = _.clamp(pageNumber - 1, 0, this._DocumentPageDisplay.getLastPageIndex());
        this.currentPageIndex = pageIndex;
        let scrollTargetPosition = 0;
        if (pageNumber !== 1) {
            const firstPageId = this._DocumentPageDisplay.getPageId(0);
            const targetPageId = this._DocumentPageDisplay.getPageId(pageIndex);
            const firstPage = this._$element[0].querySelector(`[id='${firstPageId}']`);
            const targetPage = this._$element[0].querySelector(`[id='${targetPageId}']`);
            scrollTargetPosition = targetPage.getBoundingClientRect().top - firstPage.getBoundingClientRect().top;
        }
        // Over-scroll a bit so that our scroll handler detects the right page
        // Scroll code requires the top of the target page to be scrolled past the viewport
        const scrollBuffer = 15 + 60; // document page actions height
        this.viewport.scrollTop = scrollTargetPosition + scrollBuffer;
        this.loadPages();
    }

    onScroll() {
        if (this.updateScrollTimeout) {
            this._$timeout.cancel(this.updateScrollTimeout);
        }
        this.updateScrollTimeout = this._$timeout(this.updateOnScroll.bind(this), 50);
    }

    toggleDocumentPageActionsVisible() {
        this.pageActionsVisible = !this.pageActionsVisible;
        this.calculateStyles();
    }

    updateOnScroll() {
        const pages = this._getPageElements();
        const { top: viewportTop, bottom: viewportBottom } = this.viewport.getBoundingClientRect();

        // Iterate over pages and find the first page that starts beneath the viewport
        // This is a STUPID rule -- why would you want to ENFORCE less descriptive indexes?
        // eslint-disable-next-line hapi/hapi-for-you
        for (let currentPageIndex = 0; currentPageIndex < this._DocumentPageDisplay.getPageCount(); currentPageIndex += 1) {
            const page = pages[currentPageIndex];
            const { top: pageTop, bottom: pageBottom } = page.getBoundingClientRect();
            this.pages[currentPageIndex].visibleInViewPort = pageTop < viewportBottom && pageBottom > viewportTop;
            if (pageTop > viewportTop) {
                // This page is below the top of the viewport
                break;
            }
        }
        // Set current page to page prior to this page we found
        currentPageIndex = _.clamp(currentPageIndex - 1, 0, this._DocumentPageDisplay.getLastPageIndex());

        const lastPageIndex = this.currentPageIndex;
        this.currentPageIndex = currentPageIndex;
        if (this.currentPageIndex !== lastPageIndex) {
            this._$scope.$emit(Events.PAGE_SCROLLED_TO, { page: this.currentPageIndex + 1 });
            this.loadPages();
        }
    }

    loadPages() {

        const minPage = Math.max(0, this.currentPageIndex - 1);
        const maxPage = Math.min(this._DocumentPageDisplay.getLastPageIndex(), this.currentPageIndex + 2);

        for (let i = minPage; i <= maxPage; i += 1) {
            this._DocumentPageDisplay.setPageShouldLoad(i);
            this.isLoading = this._DocumentPageDisplay.isAnyPageLoading();
        }
    }

    onLoadImage(event, pageIndex) {
        this._DocumentPageDisplay.setPageHasLoaded(pageIndex, event.target);

        this.isLoading = this._DocumentPageDisplay.isAnyPageLoading();
        this.calculateStyles();
    }

    _imgLoadError(event, pageIndex, pageNumber) {
        if (this.unloadablePages[pageNumber]) {
            return;
        }
        this.unloadablePages[pageNumber] = true;
        this._DocumentPageDisplay.setPageLoadError(pageIndex, event.target);

        const unloadablePages = _.keys(this.unloadablePages);
        const failedPages = this._commaJoin(unloadablePages);
        const firstWord = unloadablePages.length > 1 ? 'Pages' : 'Page';
        this._$scope.$emit('pageLoadError', {
            message: `${firstWord} ${failedPages} could not be loaded. Please contact customer support.`
        });

        this._modalHelper.open({
            animation: false,
            component: 'document-page-load-error',
            size: 'md',
            resolve: {
                pageNumber: () => pageNumber
            }
        });
    }

    _commaJoin(array) {

        let string = '';
        if (array.length >= 3) {
            string = `${array.slice(0, -1).join(', ')}, and ${array[array.length - 1]}`;
        }
        else if (array.length === 2) {
            string = array.join(' and ');
        }
        else {
            string = array.join();
        }
        return string;
    }

    _calculateViewportWidth(resizeSize) {
        if (resizeSize) {
            this._DocumentViewerRotation.setViewportWidth(this._DocumentViewerRotation.viewportWidth + resizeSize);
            return;
        }

        const viewportBounds = this.viewport.getBoundingClientRect();
        this._DocumentViewerRotation.setViewportWidth(viewportBounds.width - 40); // 40px marginX width
    }

    onResize() {
        this.recalculatingStyles = true;
        this.debouncedOnResize();
    }

    _debouncedOnResize() {
        this._$timeout(() => {
            this._calculateViewportWidth();
            this.calculateStyles();
            this._$scope.$broadcast(Events.PAGE_RESIZE, { zoomFactor: this.zoomFactor });
            this.recalculatingStyles = false;
        });
    }

    _getPageElements() {
        return this._$element[0].getElementsByClassName('page');
    }

    _getViewportElement() {
        return this._$element[0].getElementsByClassName('pages-viewport')[0];
    }

    updateZoomFactor(factor) {

        if (!factor) {
            factor = this._DocumentViewerRotation.calculateFitToWidthZoomFactor(this.pages[0]);
        }

        this.zoomFactor = factor;
        this._DocumentViewerRotation.setZoomFactor(this.zoomFactor);
        this.calculateStyles();
        this._$scope.$broadcast(Events.PAGE_RESIZE, { zoomFactor: this.zoomFactor });
        this._$timeout(() => {
            this.goToPage(this.currentPage);
        });
    }

    goToPage(page) {
        this.currentPage = page;
        this._$scope.$broadcast(Events.PAGE_NAVIGATE_TO, { page });
    }

    updateRotationAngle(rotationAngle) {

        this._DocumentViewerRotation.setRotationAngle(rotationAngle);
        this.calculateStyles();
        this._$scope.$broadcast(Events.PAGE_ROTATE, { rotationAngle });
        this._$timeout(() => {
            this.goToPage(this.currentPage);
        });
    }

    calculateStyles() {
        this.pages.forEach((page) => {
            page.pageWrapperStyle = this._DocumentViewerRotation.calculatePageDimensions(page, this.pages[page.pageIndex - 1]);
            page.pageStyle = this._DocumentViewerRotation.calculatePageRotation(page);
        });
        this.scrollPaneStyle = this._DocumentViewerRotation.calculateScrollPaneStyle(this.pages, this.pageActionsVisible);
    }

    handlePageTouchEnd($event) {
        if (this.selectedTool === ToolMode.NONE) {
            return;
        }
        const touches = $event.changedTouches;
        const { pageIndex } = $event.target.dataset;
        if (!this.activeTouch) {
            this.activeTouch = {
                touch: $event.changedTouches.item(0),
                pageIndex
            };
            // Keep touch active for a few moments before disabling
            const { activeTouch } = this;
            this._$timeout(() => {
                if (this.activeTouch === activeTouch) {
                    this.activeTouch = null;
                }
            }, 1000);
        }
        else {
            if (pageIndex !== this.activeTouch.pageIndex) {
                return;
            }

            // We have one active touch, the next touch should represent a selection event
            const firstTouch = this.activeTouch.touch;
            const secondTouch = touches.item(0);
            this.handlePageMouseDown(firstTouch);
            this.handlePageMouseUp(secondTouch);
            this.activeTouch = null;
        }
    }

    onSelectPrevious(source) {
        if (!source.formField) {
            // TODO: select previous for annotations
            return;
        }

        const formField = this._DocumentFormField.getPrevious(source.formField);
        const page = this.pages.find((p) => p.pageNumber === formField.pageNumber);
        this._DocumentViewerStore.setSelectedObject({
            annotation: this._DocumentFormField.getAnnotationForFormField(formField, page),
            page,
            formField
        });
        if (this.currentPage !== formField.pageNumber) {
            this.goToPage(formField.pageNumber);
        }
    }

    onSelectNext(source) {
        if (!source.formField) {
            // TODO: select next for annotations
            return;
        }
        const formField = this._DocumentFormField.getNext(source.formField);
        const page = this.pages.find((p) => p.pageNumber === formField.pageNumber);
        this._DocumentViewerStore.setSelectedObject({
            annotation: this._DocumentFormField.getAnnotationForFormField(formField, page),
            page,
            formField
        });
        if (this.currentPage !== formField.pageNumber) {
            this.goToPage(formField.pageNumber);
        }
    }

    _handlePageMouseDown($event) {
        const { pageIndex } = $event.target.dataset;
        if (!this._DocumentPageDisplay.hasPageLoaded(pageIndex)) {
            return;
        }

        const page = this._DocumentPageDisplay.getPage(pageIndex);

        this._DocumentViewerStore.setSelectedObject(undefined);
        this._$scope.$broadcast(Events.PAGE_MOUSEDOWN, this._getPageEventData($event, page));
    }

    _handlePageMouseMove($event) {
        const { pageIndex } = $event.target.dataset;
        if (!this._DocumentPageDisplay.hasPageLoaded(pageIndex)) {
            return;
        }
        const page = this._DocumentPageDisplay.getPage(pageIndex);
        this._$scope.$broadcast(Events.PAGE_MOUSEMOVE, this._getPageEventData($event, page));
    }

    _handlePageMouseUp($event) {
        const { pageIndex } = $event.target.dataset;
        const page = this._DocumentPageDisplay.getPage(pageIndex);
        if (!page.loaded) {
            return;
        }
        this._$scope.$broadcast(Events.PAGE_MOUSEUP, this._getPageEventData($event, page));
    }

    _getPageEventData($event, page) {
        const data = this._DocumentViewerPageEvent.getPageDataFromEvent($event);
        data.page = page;
        return data;
    }
}

DocumentViewerController.$inject = [
    '$scope',
    '$element',
    '$timeout',
    'CurrentSession',
    'DocumentViewerPageEvent',
    'DocumentAnnotation',
    'DocumentFormField',
    'DocumentPageDisplay',
    'DefaultSigningReason',
    'modalHelper',
    'DocumentViewerRotation',
    'DocumentViewerStore'
];

export default DocumentViewerController;
