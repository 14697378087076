import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';

import { Binder, Folder, Team } from '@app/shared/models';
import { calculateEntityPath, EntityPathItem } from '@app/shared/documents/calculate-entity-path.util';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { DocumentDownloadOptions } from './document-download.component.types';
import template from './document-download.component.html';
import styles from './document-download.component.scss';

@Component({
    selector: 'document-download',
    template,
    styles: [String(styles)]
})
export class DocumentDownloadComponent implements OnInit {
    @Input() options: DocumentDownloadOptions;
    @Input() parent: Binder | Folder;
    @Input() team: Team;
    @Input() newDocViewerFeatureEnabled: boolean;
    @Output() download = new EventEmitter<DocumentDownloadOptions>();

    includeSignature = true;
    includeAllVersionsOfDocuments = false;
    includesMultipleDocuments = false;
    isSingleLogDownload = false;

    downloadPreview: string;
    theRestOfDownloadPreview: string;
    theRestOfDownloadDocumentNames: string[];
    path: EntityPathItem[];
    logDownloadFileType: 'pdf' | 'csv' = 'pdf';

    constructor(
        private modalRef: BsModalRef
    ) { }

    ngOnInit(): void {
        this.includesMultipleDocuments = this.options.documents.length > 1;
        const entityForPath = this.includesMultipleDocuments
            ? this.parent
            : this.options.documents[0];
        this.path = calculateEntityPath(entityForPath, this.team);

        this.isSingleLogDownload = !this.includesMultipleDocuments && this.options.documents[0].subType === 'log';
        const documentNames = this.options.documents.map((doc) => doc.name);
        this.downloadPreview = documentNames.slice(0, 3).join(', ');
        if (documentNames.length > 3) {
            this.theRestOfDownloadPreview = `and ${documentNames.length - 3} others...`;
            this.theRestOfDownloadDocumentNames = documentNames.splice(3, documentNames.length - 1);
        }
    }

    onStartDownload(): void {
        this.download.emit({
            ...this.options,
            includeSignature: this.includeSignature,
            logDownloadFileType: this.logDownloadFileType,
            includeAllVersionsOfDocuments: this.includeAllVersionsOfDocuments
        });
        this.cancel();
    }

    cancel(): void {
        this.modalRef.hide();
    }
}
