import * as _ from 'lodash';

import { sortByLexicographically } from '@app/widgets/sort/sort-by-lexicographically.util';
import { sortBrowseTreeLexicographically } from '@app/widgets/sort/sort-browse-tree-lexicographically.util';
import { VirtualTreeSelectionMode } from '@app/widgets/virtual-tree/virtual-tree.component.types';
import {
    Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';

import { TeamService } from '@app/shared/teams/team.service';
import { BindersService } from '@app/shared/binders/binders.service';
import { CurrentSessionService } from '@app/core/current-session.service';
import { ApiErrorsService } from '@app/shared/api-error/api-errors.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
    Binder, BrowseNode, BrowseTree, Team
} from '@app/shared/models';
import { ItemPermission } from '@app/shared/permissions/permission-validator.service.types';
import { BrowseParams } from '@app/shared/teams/teams.service.types';
import { PermissionMiniModel } from '@app/shared/permissions/permissions.service.types';
import { of } from 'rxjs';
import {
    switchMap, finalize, catchError, map
} from 'rxjs/operators';
import style from './role-permissions-form.component.scss';
import template from './role-permissions-form.component.html';
import { RolesService } from '../../roles.service';
import { OnSaveRolePermissionForm, PermissionSet, ProgressSteps } from './role-permissions-form.component.types';
import { GetRolePermissionsResponse } from '../../roles.service.types';

@Component({
    selector: 'role-permissions-form',
    template,
    styles: [String(style)]
})
export class RolePermissionsFormComponent implements OnInit {
    @Input() mode: string;
    @Input() permissionSetType: string;
    @Input() roleId: string;
    @Input() selectedItems: { id: string; type: string }[];
    @Input() permissionSet?: PermissionSet;

    @Output() onSave = new EventEmitter<OnSaveRolePermissionForm>();

    selectionMode: VirtualTreeSelectionMode;
    progress: number;
    isProcessing: boolean;
    isValid: boolean;
    loadingRoot: boolean;

    loadItem: (params: BrowseParams) => Promise<BrowseNode[]>;
    isItemSelectable: (item: BrowseNode) => boolean;

    _currentTeam: Team;
    progressSteps: ProgressSteps;
    unselectableItems: string[];
    permissions: ItemPermission

    rootItems: Binder[];

    readonly zendenskLink = 'https://florencehealthcare.zendesk.com/hc/en-us/articles/115007344308-User-Permissions-Overview';
    showZendeskTooltip = false;

    constructor(
        private Teams: TeamService,
        private Binders: BindersService,
        private Roles: RolesService,
        private CurrentSession: CurrentSessionService,
        private ApiErrors: ApiErrorsService,
        private modalInstance: BsModalRef
    ) {

        this.selectionMode = VirtualTreeSelectionMode.MULTI_SELECT;
        this.progress = 1;
        this.isProcessing = false;
        this.isValid = false;
        this.loadingRoot = false;

        this.loadItem = this._loadItem.bind(this);
        this.isItemSelectable = this._isItemSelectable.bind(this);
    }

    ngOnInit(): void {
        if (!this.permissionSet) {
            this.permissionSet = {} as unknown as PermissionSet;
        }
        this._currentTeam = this.CurrentSession.getCurrentTeam();
        this.progressSteps = this._getSteps();
        this._loadRoot();
        this._loadPermissions();
    }

    cancel(): void {
        this.modalInstance.hide();
    }

    setProgress(n: number): void {
        this.progress = Number(n);
    }

    isLastStep(): boolean {
        return this.progress === Math.max(...Object.keys(this.progressSteps).map((key) => Number(key)));
    }

    _isItemSelectable(item) {
        return item.type === this.permissionSetType && !_.includes(this.unselectableItems, item.id);
    }

    isStepValid() {
        if (this.progressSteps[this.progress] === 'Location') {
            const a = !!this.selectedItems.length;
            return a;
        }

        if (this.progressSteps[this.progress] === 'Permissions') {
            if (this.mode === 'create' && this.permissions && this.permissions.tree) {
                return this.isAnyPermissionChecked(this.permissions.tree.permissions);
            }
            return true;
        }
    }

    isAnyPermissionChecked(permissions: { [key: string]: PermissionMiniModel }): boolean {
        return Object.values(permissions).some((permission) => permission.has || permission.hasCheckedChildren);
    }

    onItemToggled = ($event) => {
        this.selectedItems = $event.selectedItems;
        if (this.mode === 'create') {
            this._loadPermissions();
        }
    }

    save() {
        this.isProcessing = true;
        const eventParams = {
            itemPermissions: this.permissions,
            items: this.selectedItems,
            onSuccess: () => {
                this.modalInstance.hide();
            },
            onError: () => {
                this.isProcessing = false;
            }
        };

        this.onSave.emit(eventParams);
    }

    _getSteps() {
        const steps = {
            1: 'Location',
            2: 'Permissions'
        };
        if (this.permissionSetType === 'team') {
            steps['1'] = steps['2'];
            delete steps['2'];
        }
        return steps;
    }

    _loadRoot(): void {
        if (this.permissionSetType === 'team') {
            return;
        }

        this.loadingRoot = true;
        this.Binders
            .getBinders(this._currentTeam.id)
            .pipe(
                switchMap((rootItems) => {
                    this.rootItems = sortByLexicographically(rootItems, 'name');
                    return this._loadPermissionsFor(rootItems.map((i) => i.id));
                }),
                switchMap(({ items }: GetRolePermissionsResponse) => {
                    if (this.permissionSet) {
                        this._setUnselectableItems(items);
                    }
                    return of(null);
                }),
                finalize(() => {
                    this.loadingRoot = false;
                }),
                catchError((error) => {
                    this.ApiErrors.handleError(error);
                    return of(null);
                })
            )
            .subscribe();
    }

    _loadItem(params: BrowseParams) {
        let loaded: BrowseTree;
        return this.Teams
            .browse(this._currentTeam.id, params)
            .pipe(
                map((resp) => {
                    loaded = sortBrowseTreeLexicographically(resp, 'name');
                    return loaded.items;
                }),
                switchMap((items) => {
                    return this._loadPermissionsFor(items.map((i) => i.id));
                }),
                map(({ items }: GetRolePermissionsResponse) => {
                    if (this.permissionSet) {
                        this._setUnselectableItems(items);
                    }
                    return loaded;
                }),
                catchError((error) => {
                    this.ApiErrors.handleError(error);
                    return of(null);
                })
            );
    }

    _loadPermissionsFor(objectIds: string[]) {
        return this.Roles.getRolePermissions(
            this._currentTeam.id,
            this.roleId,
            {
                skipPagination: true,
                objectIds
            }
        );
    }

    _loadPermissions() {
        if (this.permissionSetType !== 'team' && !this.selectedItems.length) {
            this.permissions = null;
            return;
        }

        this.Teams
            .loadSubjectPermissionsForObject({
                teamId: this._currentTeam.id,
                subjectId: this.roleId,
                subjectType: 'role',
                objectId: this.selectedItems[0].id,
                objectType: this.permissionSetType
            })
            .subscribe((permissions) => {
                this.permissions = permissions;
                this.Teams.setHasCheckedOrInheritedChildren(this.permissions.tree);
            });
    }

    _setUnselectableItems(items) {
        this.unselectableItems = [
            ...this.unselectableItems || [],
            ...items.map((i) => i.objectId).filter((i) => i !== this.permissionSet.objectId)
        ];
    }
}
