import {
    Component, EventEmitter, Input, OnChanges, OnInit, Output
} from '@angular/core';
import { forkJoin } from 'rxjs';

import { HttpErrorResponse } from '@angular/common/http';
import { NotificationsService } from '@app/core/notifications/notifications.service';
import { Document, MonitorReview } from '@app/shared/models';
import { TeamService } from '@app/shared/teams/team.service';
import * as _ from 'lodash';
import { ModalsService } from '@app/shared/modal-helper/modals.service';
import template from './monitor-review-actions.component.html';
import { DocumentMonitorReviewService } from '../../document-monitor-review.service';
import { MonitorReviewEditComponent } from '../monitor-review-edit/monitor-review-edit.component';
import { MonitorReviewEditSubmitEvent, MonitorReviewSubmitComment } from '../monitor-review-edit/monitor-review-edit.component.types';
import { MonitorReviewApproveComponent } from '../monitor-review-approve/monitor-review-approve.component';
import { MonitorReviewOrApproveEvent } from '../monitor-review-approve/monitor-review-approve.component.types';
import { MonitorReviewApproval, MonitorReviewSubmit, MonitorReviewsUpdateEvent } from './monitor-review-actions.component.types';

@Component({
    selector: 'monitor-review-actions',
    template
})

export class MonitorReviewActionsComponent implements OnInit, OnChanges {

    @Input() doc: Document;
    @Input() monitorReviews: MonitorReview[];
    @Output() onMonitorReviewsUpdate = new EventEmitter<MonitorReviewsUpdateEvent>();

    canApprove: boolean;
    canReview: boolean;
    canCreate: boolean;
    isDocumentLocked = false;

    constructor(
        private MonitorReviewsService: DocumentMonitorReviewService,
        private Teams: TeamService,
        private Notifications: NotificationsService,
        private Modals: ModalsService
    ) {
    }

    ngOnInit(): void {
        this.canApprove = this.doc.subType !== 'placeholder' && this.doc.permissions.documentApproveMonitorReview && !this.Teams.currentTeam.settings.monitorReviewStatus.disableMonitorApprovedStatus;
        this.canReview = this.doc.subType !== 'placeholder' && this.doc.permissions.documentReviewedMonitorReview && !this.Teams.currentTeam.settings.monitorReviewStatus.disableMonitorReviewedStatus;
        this.canCreate = this.doc.permissions.documentCreateCloseQuery;
        this.isDocumentLocked = this.doc.isLocked;
    }

    ngOnChanges(changes: ng.IOnChangesObject): void {
        if (changes.monitorReviews) {
            this.monitorReviews = _.cloneDeep(this.monitorReviews);
        }
    }

    approveMonitorReview(approval: MonitorReviewApproval, monitorReview: MonitorReview) {
        return this.MonitorReviewsService
            .approve(approval.documentId.toString(), approval)
            .subscribe(
                (data) => {
                    if (!monitorReview) {
                        this.monitorReviews.push(data.monitorReview);
                    }
                    else {
                        Object.assign(this.monitorReviews.find((r) => r.id === monitorReview.id), data.monitorReview);
                    }
                    this.Notifications.success('Document marked as approved!');
                    this._onMonitorReviewsUpdate();
                },
                (error: HttpErrorResponse) => {
                    this._handleHttpError(error);
                }
            );
    }

    reviewMonitorReview(approval, monitorReview: MonitorReview) {
        return this.MonitorReviewsService
            .reviewed(approval.documentId.toString(), approval)
            .subscribe(
                (data) => {
                    if (!monitorReview) {
                        this.monitorReviews.push(data.monitorReview);
                    }
                    else {
                        Object.assign(this.monitorReviews.find((r) => r.id === monitorReview.id), data.monitorReview);
                    }
                    this.Notifications.success('Document marked as reviewed!');
                    this._onMonitorReviewsUpdate();
                },
                (error: HttpErrorResponse) => {
                    this._handleHttpError(error);
                }
            );
    }

    openApproveModal() {
        if (!this.canApprove) {
            return;
        }

        const { teamId, id: documentId } = this.doc;
        forkJoin({
            paywalls: this.MonitorReviewsService.getPaywalls(teamId, documentId.toString()),
            inheritedLabels: this.MonitorReviewsService.getInheritedLabels(teamId, documentId.toString()),
            studyLabel: this.MonitorReviewsService.getInheritedStudyLabel(documentId.toString())
        }).subscribe((data) => {

            const initialState = {
                document: this.doc,
                monitorReviews: this.monitorReviews,
                isReview: false,
                inheritedLabels: data.inheritedLabels,
                paywallOrgs: data.paywalls,
                studyLabel: data.studyLabel
            };

            const approveModal = this.Modals.show(MonitorReviewApproveComponent, {
                class: 'modal-md',
                initialState
            });

            approveModal.content.onApprovalSubmit
                .subscribe((reviewEvent) => {
                    const { approval, monitorReview } = reviewEvent;
                    this.approveMonitorReview(approval, monitorReview);
                    approveModal.hide();
                });
        });
    }

    openReviewModal() {
        if (!this.canReview) {
            return;
        }

        const { teamId, id: documentId } = this.doc;
        forkJoin({
            paywalls: this.MonitorReviewsService.getPaywalls(teamId, documentId.toString()),
            inheritedLabels: this.MonitorReviewsService.getInheritedLabels(teamId, documentId.toString()),
            studyLabel: this.MonitorReviewsService.getInheritedStudyLabel(documentId.toString())
        }).subscribe((data) => {

            const initialState = {
                document: this.doc,
                monitorReviews: this.monitorReviews,
                isReview: true,
                inheritedLabels: data.inheritedLabels,
                paywallOrgs: data.paywalls,
                studyLabel: data.studyLabel
            };

            const reviewModal = this.Modals.show(MonitorReviewApproveComponent, {
                class: 'modal-md',
                initialState
            });

            reviewModal.content.onApprovalSubmit
                .subscribe((reviewEvent: MonitorReviewOrApproveEvent) => {
                    const { approval, monitorReview } = reviewEvent;
                    this.reviewMonitorReview(approval, monitorReview);
                    reviewModal.hide();
                });

        });
    }

    openCreateReviewModal() {
        if (!this.canCreate) {
            return;
        }

        const { teamId, id: documentId } = this.doc;
        forkJoin({
            paywalls: this.MonitorReviewsService.getPaywalls(teamId, documentId.toString()),
            studyLabel: this.MonitorReviewsService.getInheritedStudyLabel(documentId.toString())
        }).subscribe((data) => {

            const initialState = {
                document: this.doc,
                monitorReviews: this.monitorReviews,
                paywallOrgs: data.paywalls,
                studyLabel: data.studyLabel
            };

            const modal = this.Modals.show(MonitorReviewEditComponent, {
                class: 'modal-md',
                initialState
            });

            modal.content.onMonitorReviewCommentSubmit
                .subscribe((reviewEventWithComment: MonitorReviewSubmitComment) => {
                    const { currentReview, comment, notifyRecipients } = reviewEventWithComment;
                    this._submitMonitorReviewComment(currentReview, comment, notifyRecipients);
                    modal.hide();
                });

            modal.content.onMonitorReviewSubmit
                .subscribe((reviewEventWithOutComment: MonitorReviewEditSubmitEvent) => {
                    this._submitMonitorReview(reviewEventWithOutComment);
                    modal.hide();
                });
        });
    }

    _submitMonitorReviewComment(monitorReview, comment: string, notifyRecipients: boolean) {
        if (monitorReview.id) {
            return this.MonitorReviewsService
                .comment(monitorReview.documentId, monitorReview.id, monitorReview.recipientIds, comment, notifyRecipients)
                .subscribe(
                    (newComment) => {
                        this.Notifications.success('Monitor Review comment added!');
                        ({ monitorReview, comment: newComment });
                    },
                    (error: HttpErrorResponse) => {
                        this._handleHttpError(error);
                    }
                );
        }
        return this.MonitorReviewsService
            .createMonitorReview(monitorReview.documentId, { ...monitorReview, comment })
            .subscribe(
                (review) => {
                    if (!monitorReview.hideNotification) {
                        this.Notifications.success('Monitor review created!');
                    }
                    this.monitorReviews.push(review);
                    this._onMonitorReviewsUpdate();
                    return this.MonitorReviewsService
                        .comment(review.documentId.toString(), review.id, monitorReview.recipientIds, comment)
                        .subscribe(
                            (newComment) => {
                                this.Notifications.success('Monitor Review comment added!');
                                ({ monitorReview: review, comment: newComment });
                            }
                        );
                },
                (error: HttpErrorResponse) => {
                    this._handleHttpError(error);
                }
            );
    }

    _submitMonitorReview(monitorReview: MonitorReviewSubmit) {

        if (monitorReview.id) {
            return this.MonitorReviewsService
                .updateStatus(
                    monitorReview.documentId.toString(),
                    monitorReview.id,
                    monitorReview.status,
                    monitorReview.recipientIds,
                    monitorReview.comment
                )
                .subscribe(
                    (review) => {
                        this.Notifications.success('Monitor review status updated!');
                        Object.assign(this.monitorReviews.find((r) => r.id === review.id), review);
                        this._onMonitorReviewsUpdate();
                        return review;
                    },
                    (error: HttpErrorResponse) => {
                        this._handleHttpError(error);
                    }
                );
        }
        return this.MonitorReviewsService
            .createMonitorReview(monitorReview.documentId.toString(), monitorReview)
            .subscribe(
                (review) => {
                    if (!monitorReview.hideNotification) {
                        this.Notifications.success('Monitor review created!');
                    }
                    this.monitorReviews.push(review);
                    this._onMonitorReviewsUpdate();
                    return review;
                },
                (error: HttpErrorResponse) => {
                    this._handleHttpError(error);
                }
            );
    }

    _onMonitorReviewsUpdate() {
        this.onMonitorReviewsUpdate.emit({ monitorReviews: this.monitorReviews });
    }

    _handleHttpError(error: HttpErrorResponse) {
        const message = (error && error.error && error.error.message);
        message ? this.Notifications.error(message) : this.Notifications.unexpectedError();
    }
}
